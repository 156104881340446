import * as React from 'react';

import { KokolingoGiftPage } from '../../fragments';

import './form.css';

const KokolingoGift = () => {
  return <KokolingoGiftPage />;
};

export default KokolingoGift;
